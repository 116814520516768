<template>
  <v-container fluid>
    <div v-if="!loadingAll">
      <v-card max-width="100%" class="mx-auto" v-if="nhomId">
        <v-toolbar color="secondary darken-3" dark>
          <v-app-bar-nav-icon></v-app-bar-nav-icon>

          <v-toolbar-title
            >{{ groupName ? groupName : "Thành viên nhóm" }}
          </v-toolbar-title>
        </v-toolbar>
        <v-layout style="height: 700px">
          <div style="width: 55%" class="mr-2 pr-3">
            <v-card>
              <v-layout align-center>
                <div class="title-group pt-3 pb-2 pl-4">
                  Thành viên trong nhóm
                </div>
                <v-spacer></v-spacer>
                <div class="pr-4">
                  <v-btn
                    tile
                    color="orange"
                    small
                    dark
                    :disabled="
                      userRemove && userRemove.length && !btnLoading > 0
                        ? false
                        : true
                    "
                    @click="xoaThanhVien"
                  >
                    <v-icon left> mdi-close </v-icon>
                    Xóa khỏi nhóm
                  </v-btn>
                </div>
              </v-layout>
              <v-layout column class="pl-5 pr-3">
                <v-row>
                  <v-col cols="6">
                    <v-text-field
                      append-icon="mdi-magnify"
                      v-model="search_tn"
                      label="Tìm kiếm"
                      clearable
                      single-line
                      hide-details
                    ></v-text-field
                  ></v-col>

                  <v-col cols="6">
                    <v-autocomplete
                      v-model="phong_ban_id_tn"
                      item-text="name"
                      item-value="id"
                      :items="phongBansTN"
                      placeholder="Phòng ban"
                      hide-details
                      clearable
                      :filter="fullTextFilter"
                      @change="changePhongBanThanhVien"
                    ></v-autocomplete
                  ></v-col>
                  <v-col cols="6">
                    <v-autocomplete
                      v-model="nhom_to_id_tn"
                      item-text="name"
                      item-value="id"
                      :items="nhomTosTN"
                      placeholder="Nhóm tổ"
                      hide-details
                      :filter="fullTextFilter"
                      clearable
                      @change="searchThanhVien"
                    ></v-autocomplete
                  ></v-col>
                  <v-col cols="6">
                    <v-select
                      v-model="chuc_vu_id_tn"
                      item-text="name"
                      item-value="id"
                      :items="chucVusTN"
                      placeholder="Chức vụ"
                      hide-details
                      clearable
                      @change="searchThanhVien"
                    ></v-select
                  ></v-col>
                </v-row>
              </v-layout>
              <br />
              <div v-if="trongNhom && trongNhom.length > 0">
                <div
                  class="pl-4 pb-2"
                  v-if="userRemove && userRemove.length > 0"
                >
                  Đã chọn {{ userRemove.length }} thành viên
                </div>
                <v-virtual-scroll
                  :height="userRemove && userRemove.length > 0 ? 470 : 500"
                  item-height="100"
                  :items="filterTrongNhom"
                >
                  <template v-slot:default="{ item }">
                    <v-list flat subheader three-line>
                      <v-list-item-group
                        v-model="userRemove"
                        multiple
                        active-class=""
                      >
                        <v-list-item :key="item.id" :value="item.user_id">
                          <template v-slot:default="{ active }">
                            <v-list-item-avatar color="indigo">
                              <img
                                v-if="item.url_image"
                                :src="imageEndpoint + item.url_image"
                                alt="ManhLe"
                              />
                              <span
                                style="color: white"
                                v-else-if="!item.url_image && item.name"
                                >{{ item.name.charAt(0).toUpperCase() }}</span
                              >
                              <v-icon v-else dark>mdi-account</v-icon>
                            </v-list-item-avatar>

                            <v-list-item-content>
                              <v-list-item-title>{{
                                item.name
                              }}</v-list-item-title>
                              <v-list-item-subtitle
                                >SĐT: {{ item.so_dien_thoai }} - Chức vụ:
                                {{ item.chuc_vu }}</v-list-item-subtitle
                              >
                              <v-list-item-subtitle
                                >Phòng ban: {{ item.phong_ban }}
                                {{
                                  item.to ? " - Tổ: " + item.to : ""
                                }}</v-list-item-subtitle
                              >
                            </v-list-item-content>
                            <v-list-item-action>
                              <v-checkbox :input-value="active"></v-checkbox>
                            </v-list-item-action>
                          </template>
                        </v-list-item>
                      </v-list-item-group>
                    </v-list>
                    <v-divider></v-divider>
                  </template>
                </v-virtual-scroll>
              </div>
              <v-layout
                style="height: 500px"
                align-center
                justify-center
                v-else
                column
              >
                <v-btn icon color="gray">
                  <v-icon style="font-size: 80px">mdi-account-multiple</v-icon>
                </v-btn>
                <div class="pt-4">Không có thành viên nào trong nhóm</div>
              </v-layout>
            </v-card>
          </div>
          <div style="width: 45%" class="pl-2">
            <v-layout align-center>
              <div class="title-group pt-3 pb-2">Thêm thành viên</div>
              <v-spacer></v-spacer>
              <div class="pr-4">
                <v-btn
                  tile
                  color="secondary"
                  small
                  dark
                  :disabled="
                    userAdd && userAdd.length && !btnLoading > 0 ? false : true
                  "
                  @click="themThanhVien"
                >
                  <v-icon left> mdi-plus </v-icon>
                  Thêm
                </v-btn>
              </div>
            </v-layout>
            <v-layout column class="pr-2">
              <v-row>
                <v-col cols="6">
                  <v-text-field
                    append-icon="mdi-magnify"
                    v-model="search"
                    label="Tìm kiếm"
                    clearable
                    single-line
                    hide-details
                  ></v-text-field
                ></v-col>

                <v-col cols="6">
                  <v-autocomplete
                    v-model="phong_ban_id"
                    item-text="name"
                    item-value="id"
                    :items="phongBans"
                    placeholder="Phòng ban"
                    hide-details
                    clearable
                    :filter="fullTextFilter"
                    @change="changePhongBanThemThanhVien"
                  ></v-autocomplete
                ></v-col>
                <v-col cols="6">
                  <v-autocomplete
                    v-model="nhom_to_id"
                    item-text="name"
                    item-value="id"
                    :items="nhomTos"
                    placeholder="Nhóm tổ"
                    hide-details
                    :filter="fullTextFilter"
                    clearable
                    @change="searchThemThanhVien"
                  ></v-autocomplete
                ></v-col>
                <v-col cols="6">
                  <v-select
                    v-model="chuc_vu_id"
                    item-text="name"
                    item-value="id"
                    :items="chucVus"
                    placeholder="Chức vụ"
                    hide-details
                    clearable
                    @change="searchThemThanhVien"
                  ></v-select
                ></v-col>
              </v-row>
            </v-layout>
            <br />
            <div v-if="userAdd && userAdd.length > 0">
              Đã chọn {{ userAdd.length }} thành viên
            </div>
            <v-virtual-scroll
              height="450"
              item-height="100"
              :items="filterNgoaiNhom"
            >
              <template v-slot:default="{ item }">
                <v-list flat subheader three-line>
                  <v-list-item-group v-model="userAdd" multiple active-class="">
                    <v-list-item :key="item.id" :value="item.user_id">
                      <template v-slot:default="{ active }">
                        <v-list-item-avatar color="indigo">
                          <img
                            v-if="item.url_image"
                            :src="imageEndpoint + item.url_image"
                            alt="ManhLe"
                          />
                          <span
                            style="color: white"
                            v-else-if="!item.url_image && item.name"
                            >{{ item.name.charAt(0).toUpperCase() }}</span
                          >
                          <v-icon v-else dark>mdi-account</v-icon>
                        </v-list-item-avatar>

                        <v-list-item-content>
                          <v-list-item-title>{{ item.name }}</v-list-item-title>
                          <v-list-item-subtitle
                            >SĐT: {{ item.so_dien_thoai }} - Chức vụ:
                            {{ item.chuc_vu }}</v-list-item-subtitle
                          >
                          <v-list-item-subtitle
                            >Phòng ban: {{ item.phong_ban }}
                            {{
                              item.to ? " - Tổ: " + item.to : ""
                            }}</v-list-item-subtitle
                          >
                        </v-list-item-content>
                        <v-list-item-action>
                          <v-checkbox :input-value="active"></v-checkbox>
                        </v-list-item-action>
                      </template>
                    </v-list-item>
                  </v-list-item-group>
                </v-list>
                <v-divider></v-divider>
              </template>
            </v-virtual-scroll>
          </div>
        </v-layout>
      </v-card>
      <v-card v-else class="mx-auto">
        <v-toolbar color="secondary darken-3" dark>
          <v-app-bar-nav-icon></v-app-bar-nav-icon>
          <v-toolbar-title>Thành viên trong nhóm</v-toolbar-title>
        </v-toolbar>
        <v-layout column align-center justify-center style="height: 450px">
          <v-btn icon color="pink">
            <v-icon style="font-size: 80px">mdi-account-multiple</v-icon>
          </v-btn>
          <div class="pt-6" style="font-size: 24px">
            Hãy chọn một nhóm để xem
          </div>
        </v-layout>
      </v-card>
    </div>

    <v-card v-else class="mx-auto">
      <v-toolbar color="secondary darken-3" dark>
        <v-app-bar-nav-icon></v-app-bar-nav-icon>
        <v-toolbar-title>Thành viên nhóm</v-toolbar-title>
      </v-toolbar>
      <v-layout column align-center justify-center style="height: 450px">
        <v-progress-circular indeterminate color="pink"></v-progress-circular>
      </v-layout>
    </v-card>
  </v-container>
</template>

<script>
import { getThanhVien, addThanhVien, xoaThanhVien } from "@/api/nhom";
import { fullTextFilter } from "../../../utils/stringHelper";
import { getPhongBan } from "@/api/phongban";
import { getNhomToTrucThuoc } from "@/api/nhomto";
import { getDanhMucCon } from "@/api/danhmuc";
import { debounce } from "lodash";

export default {
  props: {
    nhomId: {
      type: Number,
    },
    groupName: {
      type: String,
    },
  },
  data: () => ({
    comment: "",
    fullTextFilter,
    imageEndpoint: process.env.VUE_APP_BASE,
    page: 1,
    pageCount: 1,
    itemsPerPage: 5,
    loadingAll: false,
    userAdd: [],
    userRemove: [],
    trongNhom: [],
    ngoaiNhom: [],
    btnLoading: false,
    phongBans: [],
    nhomTos: [],
    phong_ban_id: null,
    nhom_to_id: null,
    chuc_vu_id: null,
    chucVus: [],
    search: null,
    filterNgoaiNhom: [],

    phongBansTN: [],
    nhomTosTN: [],
    phong_ban_id_tn: null,
    nhom_to_id_tn: null,
    chuc_vu_id_tn: null,
    chucVusTN: [],
    search_tn: null,
    filterTrongNhom: [],
  }),
  watch: {
    async nhomId(val) {
      this.userRemove = [];
      this.userAdd = [];
      this.trongNhom = [];
      this.ngoaiNhom = [];
      if (val) {
        await this.getData(val);
      }
    },

    search: debounce(function() {
      this.searchThemThanhVien();
    }, 300),
    search_tn: debounce(function() {
      this.searchThanhVien();
    }, 300),
  },

  mounted() {
    this.getDataFromParam();
    this.getDanhMuc();
  },
  methods: {
    getDataFromParam() {
      if (Number.isInteger(Number(this.$route.params.id))) {
        this.getData(this.$route.params.id);
      } else return;
    },
    async getDanhMuc() {
      let phongbans = await getPhongBan({
        perPage: 999,
      });
      this.chucVus = await getDanhMucCon({ code: "CHUCVU" });
      this.chucVusTN = [...this.chucVus];
      this.phongBans = phongbans.data;
      this.phongBansTN = [...this.phongBans];
    },
    async getNhomTo() {
      if (this.phong_ban_id) {
        let data = await getNhomToTrucThuoc({
          phong_ban_id: this.phong_ban_id,
        });
        this.nhomTos = data;
      } else this.nhomTos = [];
    },
    async getNhomToTV() {
      if (this.phong_ban_id_tn) {
        let data = await getNhomToTrucThuoc({
          phong_ban_id: this.phong_ban_id_tn,
        });
        this.nhomTosTN = data;
      } else this.nhomTosTN = [];
    },

    async getData(nhomId) {
      if (!Number.isInteger(nhomId)) {
        this.trongNhom = [];
        this.ngoaiNhom = [];
        return;
      }
      let data = await getThanhVien({
        nhom_id: nhomId,
      });
      this.trongNhom = data.trong_nhom;
      this.ngoaiNhom = data.ngoai_nhom;
      this.filterNgoaiNhom = [...this.ngoaiNhom];
      this.filterTrongNhom = [...this.trongNhom];
    },
    changePhongBanThemThanhVien() {
      this.getNhomTo();
      this.searchThemThanhVien();
    },
    changePhongBanThanhVien() {
      this.getNhomToTV();
      this.searchThanhVien();
    },
    searchThanhVien() {
      let fData = [...this.trongNhom];
      if (this.search_tn) {
        const term = this.search_tn.toLocaleLowerCase();
        fData = fData.filter(
          (el) =>
            (el.name && el.name.toLocaleLowerCase().includes(term)) ||
            (el.so_dien_thoai &&
              el.so_dien_thoai.toLocaleLowerCase().includes(term))
        );
      }
      if (this.phong_ban_id_tn) {
        fData = fData.filter((el) => el.phong_ban_id == this.phong_ban_id_tn);
      }
      if (this.nhom_to_id_tn) {
        fData = fData.filter((el) => el.nhom_to_id == this.nhom_to_id_tn);
      }
      if (this.chuc_vu_id_tn) {
        fData = fData.filter((el) => el.chuc_vu_id == this.nhom_to_id_tn);
      }
      this.filterTrongNhom = fData;
    },

    searchThemThanhVien() {
      let fData = [...this.ngoaiNhom];

      if (this.search) {
        const term = this.search.toLocaleLowerCase();
        fData = fData.filter(
          (el) =>
            (el.name && el.name.toLocaleLowerCase().includes(term)) ||
            (el.so_dien_thoai &&
              el.so_dien_thoai.toLocaleLowerCase().includes(term))
        );
      }
      if (this.phong_ban_id) {
        fData = fData.filter((el) => el.phong_ban_id == this.phong_ban_id);
      }
      if (this.nhom_to_id) {
        fData = fData.filter((el) => el.nhom_to_id == this.nhom_to_id);
      }
      if (this.chuc_vu_id) {
        fData = fData.filter((el) => el.chuc_vu_id == this.chuc_vu_id);
      }
      this.filterNgoaiNhom = fData;
    },
    async themThanhVien() {
      if (!this.userAdd && this.userAdd.length == 0) {
        return;
      }
      try {
        this.btnLoading = true;
        await addThanhVien({
          nhom_id: this.$route.params.id,
          thanh_viens: this.userAdd,
        });
        this.userAdd = [];
        await this.getData(this.$route.params.id);
        this.searchThemThanhVien();
        this.btnLoading = false;
        this.$toast.info("Cập nhật thành công", {
          position: "top-center",
          timeout: 2000,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          draggablePercent: 0.6,
          showCloseButtonOnHover: false,
          hideProgressBar: true,
          closeButton: "button",
          icon: true,
        });
      } catch (error) {
        this.btnLoading = false;
        this.userAdd = [];
      }
    },
    xoaThanhVien() {
      this.$confirmBox.show({
        title: "Xóa thành viên khỏi nhóm",
        width: 500,
        body:
          "Bạn có chắc chắn muốn xóa " +
          this.userRemove.length +
          " thành viên ra khỏi nhóm!" +
          "<br/>" +
          "Lưu ý: Các thành viên này vẫn xem được các tin trước đó",
        action: async () => {
          this.btnLoading = true;
          await xoaThanhVien({
            nhom_id: this.$route.params.id,
            thanh_viens: this.userRemove,
          });
          this.btnLoading = false;
          this.userRemove = [];
        },

        onDone: () => this.getData(this.$route.params.id),
      });
    },
  },
};
</script>

<style>
.title-group {
  font-size: 22px;
  color: black;
}
</style>
